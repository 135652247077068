import type { FederationRuntimePlugin } from '@module-federation/enhanced/runtime';

// Описание и типы хуков - https://module-federation.io/plugin/dev/index.html#hooks
// @ts-expect-error no type
const runtimePlugin: () => FederationRuntimePlugin = function () {
  return {
    name: 'my-runtime-plugin',

    beforeInit(args: unknown) {
      //console.log('beforeInit: ', args);
      return args;
    },

    beforeRequest(args: unknown) {
      //console.log('beforeRequest: ', args);
      return args;
    },

    afterResolve(args: unknown) {
      //console.log('afterResolve', args);
      return args;
    },

    onLoad(args: unknown) {
      //console.log('onLoad: ', args);
      return args;
    },

    async loadShare(args: unknown) {
      //console.log('loadShare:', args);
      return args;
    },

    async beforeLoadShare(args: unknown) {
      //console.log('beforeloadShare:', args);
      return args;
    },
  };
};
export default runtimePlugin;
